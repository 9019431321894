// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-hilfe-anbieten-js": () => import("./../../../src/pages/hilfe-anbieten.js" /* webpackChunkName: "component---src-pages-hilfe-anbieten-js" */),
  "component---src-pages-hilfe-finden-js": () => import("./../../../src/pages/hilfe-finden.js" /* webpackChunkName: "component---src-pages-hilfe-finden-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-templates-markdown-js": () => import("./../../../src/templates/markdown.js" /* webpackChunkName: "component---src-templates-markdown-js" */)
}

